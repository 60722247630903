import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ProductList.module.css';
import { ElimiarProducto } from '../../reducer/actions';
import { useDispatch } from 'react-redux';

const ProductList = () => {
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [editedProduct, setEditedProduct] = useState({
        name: '',
        description: '',
        price: '',
        stock: '',
        images: [],
        selectedImage: '',
        imageIndex: -1
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://rezuma-back.onrender.com/Products');
            setProducts(response.data);
            /* console.log(response.data) */
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const deleteProduct = async (id) => {
        try {
            dispatch(ElimiarProducto(id));
            // Actualiza la lista de productos en el estado
            setProducts((prevProducts) => prevProducts.filter(product => product.id !== id));
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const handleEditChange = (e) => {
        const { name, value, files } = e.target;
        setEditedProduct({
            ...editedProduct,
            [name]: files ? files[0] : value,
            selectedImage: files ? URL.createObjectURL(files[0]) : editedProduct.selectedImage
        });
    };

    const handleEditClick = (product) => {
        setEditingProductId(product.id);
        setEditedProduct({
            ...product,
            selectedImage: product.images[0], // Selecciona la primera imagen por defecto
        });
    };

    const handleImageSelect = (image, index) => {
        setEditedProduct({
            ...editedProduct,
            selectedImage: image,
            imageIndex: index
        });
    };

    const handleConfirmClick = async (productId) => {
        try {
            let updatedProduct = { ...editedProduct };

            if (editedProduct.image && editedProduct.image instanceof File) {
                const formData = new FormData();
                formData.append('file', editedProduct.image);
                const uploadResponse = await axios.post('https://rezuma-back.onrender.com/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                updatedProduct.image = uploadResponse.data.filePath;
            } else {
                updatedProduct.image = editedProduct.selectedImage;
            }

            await axios.put(`https://rezuma-back.onrender.com/Products/${productId}`, updatedProduct);
            setEditingProductId(null);
            fetchProducts(); // Actualizar la lista después de guardar los cambios
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const toggleProductStatus = async (productId, currentStatus) => {
        try {
            const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
           /*  console.log(newStatus) */
            /* await axios.put(`http://localhost:3001/Products/${productId}`, {status: newStatus} ); */
            await axios.put(`https://rezuma-back.onrender.com/Products/${productId}`, {status: newStatus} );
            fetchProducts(); // Actualizar la lista después de cambiar el estado
        } catch (error) {
            console.error('Error updating product status:', error);
        }
    };

    // Ordenar productos: los inactivos al final
    const sortedProducts = [...products].sort((a, b) => a.status === 'inactive' ? 1 : -1);

    return (
        <div className={styles.productList}>
            {sortedProducts.map((product) => (
                <div key={product.id} className={`${styles.productCard} ${product.status === 'inactive' ? styles.inactive : ''}`}>
                    {editingProductId === product.id ? (
                        <>
                            <label htmlFor="name">Nombre:</label>
                            <input
                                type="text"
                                name="name"
                                value={editedProduct.name}
                                onChange={handleEditChange}
                                className={styles.input}
                            />
                            <label htmlFor="description">Descripción:</label>
                            <textarea
                                name="description"
                                value={editedProduct.description}
                                onChange={handleEditChange}
                                className={styles.textarea}
                            />
                            <label htmlFor="price">Precio:</label>
                            <input
                                type="text"
                                name="price"
                                value={editedProduct.price}
                                onChange={handleEditChange}
                                className={styles.input}
                            />
                            <label htmlFor="stock">Stock:</label>
                            <input
                                type="text"
                                name="stock"
                                value={editedProduct.stock}
                                onChange={handleEditChange}
                                className={styles.input}
                            />

                            <label>Seleccionar imagen:</label>
                            {/* <div className={styles.imageContainer}>
                                {editedProduct.images.map((img, index) => (
                                    <img
                                        key={index}
                                        src={img}
                                        alt={`Producto ${index}`}
                                        className={img === editedProduct.selectedImage ? styles.selectedImage : ''}
                                        onClick={() => handleImageSelect(img, index)}
                                    />
                                ))}
                            </div> */}

                         {/*    {editedProduct.selectedImage && (
                                <>
                                    <label>Imagen seleccionada para cambiar:</label>
                                    <img src={editedProduct.selectedImage} alt="Imagen seleccionada" className={styles.selectedImagePreview} />
                                    <label htmlFor="image">Cambiar imagen seleccionada:</label>
                                    <input
                                        type="file"
                                        name="image"
                                        onChange={handleEditChange}
                                        className={styles.input}
                                    />
                                </>
                            )} */}
                            <button onClick={() => handleConfirmClick(product.id)} className={styles.button}>
                                Confirmar
                            </button>
                        </>
                    ) : (
                        <>
                            <img src={product.images[0]} alt={product.name} className={styles.image} />
                            <h3>{product.name}</h3>
                            <p>{product.description}</p>
                            <p>Precio: ${product.price}</p>
                            <p>Stock: {product.stock}</p>
                            <button onClick={() => handleEditClick(product)} className={styles.button}>
                                Editar
                            </button>
                        </>
                    )}
                    <button onClick={() => toggleProductStatus(product.id, product.status)} className={styles.button}>
                        {product.status === 'active' ? 'Desactivar' : 'Activar'}
                    </button>
                    <button onClick={() => deleteProduct(product.id)} className={styles.button}>
                        ELIMINAR
                    </button>
                </div>
            ))}
        </div>
    );
}

export default ProductList;
