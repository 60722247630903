import React, { useEffect, useState } from 'react';
import { initMercadoPago, Payment } from '@mercadopago/sdk-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './MercadoPago.module.css';
import { postOrder, limpiarCarrito, sendEmail } from '../../reducer/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, ToastContainer } from 'react-bootstrap';

const MercadoPago = ({ data, orderData }) => {

  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState('all');
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Inicia en false para evitar mostrar el modal inmediatamente
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

 /*  useEffect(() => {
    initMercadoPago('TEST-4ea23916-483b-4696-8988-613c353bed4c', { locale: 'es-AR' });
  }, []); */
  
  useEffect(() => {
    initMercadoPago('APP_USR-c96dc4b0-31f3-42b2-b0b0-9ce75f066d1b', { locale: 'es-AR' });
  }, []);

  useEffect(() => {
    let timer;
    if (showModal) {
      timer = setTimeout(() => {
        setShowModal(false); // Oculta el modal después de 15 segundos
        dispatch(limpiarCarrito());
        navigate('/');
      }, 15000);
    }
    return () => clearTimeout(timer);
  }, [showModal, navigate]);

  const [initialization, setInitialization] = useState({
    amount: orderData.unit_price,
  });

  const customization = {
    paymentMethods: {
      creditCard: paymentMethod === 'creditCard' ? 'all' : '',
      debitCard: paymentMethod === 'debitCard' ? 'all' : '',
      mercadoPago: 'all',
      maxInstallments: 1,
      minInstallments: 1,
    },
  };

  function getFirstThreeLetters(orderData) {
    const id = orderData.kitsCompra[0][0].id;
    return id.slice(0, 3);
  }

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    console.log(formData)
    
      /* const { data } = await axios.post("http://localhost:3001/MercadoPago/webhook", { */
      const data  = await axios.post("https://rezuma-back.onrender.com/MercadoPago/webhook", {
        data: formData,
        items: "Productos para comprar",
      });
      console.log(data.data.status, ' esto llega');
      if (data.data.status === 'approved') {  
        let id = getFirstThreeLetters(orderData);
        orderData.id = id;   
        console.log(orderData, ' orderData')
        setShowModal(true);
        dispatch(sendEmail(orderData))
        dispatch(postOrder(orderData));
      }else{
        setShowToast(true);
       /*  alert('Tu pago fue rechazado. Por favor, volvé a intentarlo') */
       /*  setTimeout(() => {
          window.location.reload();  
        }, 0); */
        
      }
      setError(null);
    
  };

  const onError = (error) => {
    
    setError('Ocurrió un error al procesar el pago. Por favor, inténtalo de nuevo.');
    console.log(error);
  };

  const onReady = () => {
    // Callback called when the Brick is ready.
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    setError(null);
  };

  const handleModalButtonClick = () => {
    setShowModal(false);
    dispatch(limpiarCarrito());
    navigate('/');
    
  };

  return (
    <div id="wallet_container" className={styles.walletContainer}>
      <ToastContainer 
        className={`position-fixed p-3 ${styles.customtoastcontainer}`} 
        style={{ top: '35%', right: '35%' }}>
        <Toast className={styles.customtoastcontainer2} onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide >
          <Toast.Header>
            <strong className="me-auto">imprevisto al generar el pago</strong>
          </Toast.Header>
         <Toast.Body>Hubo un error en tu pago, por favor vuelva a intentarlo</Toast.Body>
      </Toast>
    </ToastContainer>
      <div className={styles.paymentMethodSelection}>
        <button 
          className={`${styles.paymentButton} ${paymentMethod === 'creditCard' ? styles.activeButton : ''}`} 
          onClick={() => handlePaymentMethodChange('creditCard')}
        >
          Tarjeta de Crédito
        </button>
        <button 
          className={`${styles.paymentButton} ${paymentMethod === 'debitCard' ? styles.activeButton : ''}`} 
          onClick={() => handlePaymentMethodChange('debitCard')}
        >
          Tarjeta de Débito
        </button>
      </div>
      {error && paymentMethod !== 'all' && <p className={styles.error}>{error}</p>}
      {
        !error && 
        <Payment
        initialization={initialization}
        customization={customization}
        onSubmit={onSubmit}
        onReady={onReady}
        onError={onError}
      />
      }

      {showModal && (
        <div className={styles.modalBackground}>
          <div className={styles.modal}>
            <p>¡Gracias por su compra!</p>
            <button onClick={handleModalButtonClick}>Aceptar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MercadoPago;
